<template>
  <div class="och-c ohc-c-wrapper">
    <div class="och-c__sf-header content">
      <div class="och-c__sf-header__wrapper">
        <header
          ref="header"
          class="ohc-c"
        >
          <div class="ohc-c__row">
            <div class="ohc-c__col ohc-c__col--go-to-prev">
              <SfButton
                data-transaction-name="Checkout Go To Prev"
                @click.native="goToPrevPage"
                class="sf-button--close ohc-c__button"
              >
                <span class="ohc-c__button-back" />
              </SfButton>
            </div>
            <div class="ohc-c__col ohc-c__col--logo">
              <ALogo />
            </div>
            <div class="ohc-c__col ohc-c__col--title">
              <h1 class="title">
                {{ $t('Checkout') }}
              </h1>
            </div>
            <div class="ohc-c__col ohc-c__col--contacts">
              <MPhoneContact
                class="ohc-c__phone-contact"
                size="small"
                :is-email-shown="false"
              />
            </div>
          </div>
        </header>
        <div class="ohc-c__mobile-title">
          <h1 class="title title--mobile">
            {{ $t('Checkout') }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue'
import ALogo from 'theme/components/atoms/a-logo'
import MPhoneContact from '../molecules/m-phone-contact'

export default {
  name: 'OCheckoutHeader',
  components: {
    MPhoneContact,
    ALogo,
    SfButton
  },
  data () {
    return {}
  },
  methods: {
    goToPrevPage () {
      this.$router.push(this.localizedRoute('/'))
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.och-c {
  background: var(--white);

  &__sf-header {
    max-width: 1210px;
    margin: 0 auto;

    &--is-sticky {
      @include for-mobile {
        position: fixed;
        width: 100%;
        overflow: hidden;
        background-color: var(--white);
        z-index: 8;
      }
    }
  }

  .title {
    font-family: var(--font-family-headers);
    font-size: var(--font-size-32);
    font-weight: var(--font-medium);
    line-height: 1;
    margin: 0;

    &--mobile {
      font-size: var(--font-size-22);
    }
  }
}
</style>
